import React, { useState } from "react";
import {
  SAText,
  SARadioGroup,
  SARadio,
  SACard,
  SAStack,
  SAInput,
  SABox,
  SAColumns,
} from "@saux/design-system-react";
import moment from "moment";
import { ConditionInput } from "../save/saveDataContainer";

interface ScheduleProps {
  information: ConditionInput;
  setInformation: React.Dispatch<React.SetStateAction<ConditionInput>>;
  setInput: Function;
  department: string;
  isScheduleSet: boolean;
  setSchedule: Function;
  setDepartment: Function;
  setIsTillEOD: Function;
  isTillEOD: boolean;
}
//ToDo: Should these be refactored to the "react" way?
var unchangedCurrentTime: string;
var totime: string = "9:00 AM";
var fromtime: string = "8:00 AM";
var date: string = "2021-7-27";
export var manual: boolean = false;
export var time: string = "8 am";
var today: string;
var currentTime: string;

const Schedule: React.FC<ScheduleProps> = ({
  information,
  setInformation,
  setInput,
  department,
  isScheduleSet,
  setSchedule,
  setDepartment,
  setIsTillEOD,
  isTillEOD,
}): JSX.Element => {
  //gets the time and date of the current day and time
  //formats it as well
  const getTimeDate = () => {
    today = new Date().toLocaleDateString();
    // currentTime = new Date().toTimeString();
    currentTime = moment().format("hh:mm A");
    unchangedCurrentTime = currentTime;
  };

  //now is selected, user chooses manual and until
  const onManualSelected = (e: any) => {
    if (e.target.value === "Until") {
      manual = false;
      getTimeDate();
      setInformation({
        ouIdentifier: information.ouIdentifier,
        closed: true,
        play_message: true,
        message: "",
        dateOf: today,
        fromTime: unchangedCurrentTime,
        toTime: information.toTime,
      });
    } else {
      manual = true;
      setInformation({
        ouIdentifier: information.ouIdentifier,
        closed: true,
        play_message: true,
        message: "",
        dateOf: today,
        fromTime: unchangedCurrentTime,
        toTime: "Manual",
      });
      //For some reason information is not being set
      setSchedule(true);
    }
    updateInputMessage();
  };

  //later is selected, must choose a date
  const dateSelected = (e: any) => {
    date = e.target.value;
    date = date.toString();
    date = moment(date).format("MM/DD/YYYY");
    setInformation({
      ouIdentifier: information.ouIdentifier,
      closed: false,
      play_message: false,
      message: "",
      dateOf: date,
      fromTime: information.fromTime,
      toTime: information.toTime,
    });
    updateInputMessage();
  };

  const [nowOrLater, setnowOrLater] = useState("Later");
  //now or later is decided
  const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    //We want to clear the department if the user changes now or later.
    setDepartment("");
    setSchedule(false);
    if (e.target.value === "Eod") {
      setIsTillEOD(true);
    } else {
      setIsTillEOD(false);
    }
    if (e.target.value === "Now") {
      setnowOrLater(e.target.value);
      getTimeDate();
      //gets current time needed for dyanmodb
      //sets up certain variables
      setInformation({
        ouIdentifier: information.ouIdentifier,
        closed: true,
        play_message: true,
        message: "",
        dateOf: today,
        fromTime: unchangedCurrentTime,
        toTime: information.toTime,
      });
    } else {
      //later is selected
      setnowOrLater(e.target.value);

      setInformation({
        ouIdentifier: information.ouIdentifier,
        closed: false,
        play_message: false,
        message: "",
        dateOf: information.dateOf,
        fromTime: information.fromTime,
        toTime: information.toTime,
      });
    }
  };

  const updateInputMessage = () => {
    if (isTillEOD) {
      setInput({
        message: (
          <p>
            Hello, and thank you for calling State Auto Insurance Company. Our{" "}
            <strong>{department}</strong> department is currently unavailable.
            We apologize for the inconvenience. Please call back the next
            business day.
          </p>
        ),
      });
    } else if (information.toTime === "Manual" || manual) {
      setInput({
        message: (
          <p>
            Hello, and thank you for calling State Auto Insurance Company. Our{" "}
            <strong>{department}</strong> department is currently unavailable.
          </p>
        ),
      });
    } else {
      setInput({
        message: (
          <p>
            Hello, and thank you for calling State Auto Insurance Company. Our{" "}
            <strong>{department}</strong> department is currently in a
            department meeting. They will be available again at{" "}
            <strong>{time}</strong> Eastern Standard Time. "
          </p>
        ),
      });
    }
  };

  const changeToTime = (e: any) => {
    let toTime = e.target.value;
    toTime = moment(toTime, "hh:mm").format("hh:mm A");
    setInformation({
      ouIdentifier: information.ouIdentifier,
      closed: information.closed,
      play_message: information.play_message,
      message: "",
      dateOf: information.dateOf,
      fromTime: information.fromTime,
      toTime: toTime,
    });
    updateInputMessage();
    setSchedule(true);
  };

  const changeFromTime = (e: any) => {
    let fromTime = e.target.value;
    fromTime = moment(fromTime, "hh:mm").format("hh:mm A");
    setInformation({
      ouIdentifier: information.ouIdentifier,
      closed: information.closed,
      play_message: information.play_message,
      message: "",
      dateOf: information.dateOf,
      fromTime: fromTime,
      toTime: nowOrLater === "Eod" ? "Manual" : information.toTime,
    });
    updateInputMessage();
    setSchedule(true);
  };

  return (
    <SACard variant="minimal" data-testid="schedule-card">
      <SABox pb="medium" sb="line" mb="large">
        <SAText type="heading-3" text="Time and Day" />
      </SABox>

      <SAStack spacing="large">
        <SARadioGroup
          value={nowOrLater}
          id="question01"
          label="Are you scheduling now or later?"
          variant="standard"
          direction="horizontal"
          onChange={(e) => onClick(e)}
          data-testid="radioGroup"
        >
          <SARadio
            value="Now"
            label="Now"
            variant="standard"
            data-testid="SARadioNow"
          />
          <SARadio
            value="Later"
            label="Later"
            variant="standard"
            data-testid="SARadioLater"
          />
          <SARadio
            value="Eod"
            label="Till EOD"
            variant="standard"
            data-testid="SARadioLater"
          />
        </SARadioGroup>
      </SAStack>

      {nowOrLater === "Now" && (
        <SAStack spacing="large">
          <SAText type="heading-2" text=" " />

          <SARadioGroup
            id="question09"
            label="How long should it be on?"
            variant="standard"
            direction="horizontal"
            onChange={(e) => onManualSelected(e)}
          >
            <SARadio
              data-testid="nowUntilButton"
              value="Until"
              label="Until"
              variant="standard"
            />
            <SARadio
              value="Until manually turned off"
              label="Until manually turned off"
              variant="standard"
            />
          </SARadioGroup>

          {manual === false && (
            <SAColumns columns={[3, 3]} spacing="small">
              <SAInput
                id="dateexample"
                label="Date"
                type="date"
                onChange={(e) => dateSelected(e)}
                data-testid="nowUntilDatePicker"
              />
              <SAInput
                id="timeexample"
                label="Time"
                type="time"
                onChange={(e) => {
                  //setting toTime
                  changeToTime(e);
                }}
                data-testid="nowUntilTimePicker"
              />
            </SAColumns>
          )}
        </SAStack>
      )}
      {nowOrLater === "Later" && (
        <SAStack spacing="large">
          <SAText type="heading-2" text=" " />
          <SAText text="Set date and time range" />
          <SAText type="heading-2" text=" " />
          <div
            style={{ justifyContent: "flex-start", display: "flex" }}
            className="input-schedule"
          >
            <SAInput
              id="dateexample"
              label="Date"
              type="date"
              onChange={(e) => dateSelected(e)}
              data-testid="laterDatePicker"
            />
            <SAInput
              id="timeexample"
              label="From"
              type="time"
              className="input"
              onChange={(e) =>
                //setting fromTime
                changeFromTime(e)
              }
              data-testid="laterFromTimePicker"
            />
            <SAInput
              id="timeexample"
              label="To"
              type="time"
              className="input"
              onChange={(e) => {
                //setting toTime
                changeToTime(e);
              }}
              data-testid="laterToTimePicker"
            />
          </div>
        </SAStack>
      )}
      {nowOrLater === "Eod" && (
        <SAStack spacing="large">
          <SAText type="heading-2" text=" " />
          <SAText text="Set date and time range" />
          <SAText type="heading-2" text=" " />
          <div
            style={{ justifyContent: "flex-start", display: "flex" }}
            className="input-schedule"
          >
            <SAInput
              id="dateexample"
              label="Date"
              type="date"
              onChange={(e) => dateSelected(e)}
              data-testid="EodDatePicker"
            />
            <SAInput
              id="timeexample"
              label="From"
              type="time"
              className="input"
              onChange={(e) =>
                //setting fromTime
                changeFromTime(e)
              }
              data-testid="EodFromTimePicker"
            />
          </div>
        </SAStack>
      )}
    </SACard>
  );
};

export default Schedule;
