import React from 'react'
import ReactDOM, { render } from 'react-dom';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink
} from "@apollo/client";


import App from './App'
// import AppSyncConfig from './config/dev.json'
import getConfig, { Env } from './config';
import Amplify, {Auth} from 'aws-amplify';

const config = getConfig(process.env.REACT_APP_ENV as Env);

Amplify.configure(config);

const auth = {
  url: config.aws_appsync_graphqlEndpoint,
  region: config.aws_appsync_region,
  auth: {
    type: config.aws_appsync_authenticationType,
    jwtToken: () =>
    Auth.currentSession()
      .then(session => session.getIdToken().getJwtToken())
      .catch(err => {
        console.error('error', err);
      })
  } as AuthOptions
}

  export const client = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(auth),
    createSubscriptionHandshakeLink(auth)
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

render(
  <ApolloProvider client={client}>
    <App />
    
  </ApolloProvider>,
  document.getElementById('root')
)