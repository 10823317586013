import React, { useContext, useEffect, useState } from "react";
import styled, { css, ThemeContext, ThemeProvider } from "styled-components";
import {
  SAUX360Theme,
  SALogo,
  SAButton,
  SACard,
  SAColumns,
  SAStack,
  SAInput,
  GlobalStyle,
  SAPopover,
  SAModal,
  SAText,
  SABox,
  SAIcon,
  SAIcons,
  SAIconSize,
} from "@saux/design-system-react";
import gql from "graphql-tag";
import { Auth } from "aws-amplify";
import {
  ConditionInput,
  InformationInterface,
  InputMessage,
} from "./saveDataContainer";
interface IProps {
  updateCondition: Function;
  input: InputMessage;
  setInput: Function;
  setInformation: Function;
  allOrOne: boolean;
  confirmationPage: boolean;
  setConfirmationPage: Function;
  information: InformationInterface["information"];
  department: string;
  queue: string;
  isScheduleSet: boolean;
  displayDepartment: boolean;
  displayScenario: boolean;
  setSaveError: Function;
}

interface Conditions {
  ouIdentifier: string;
  closed: boolean;
  play_message: boolean;
  message: string;
}

interface Items {
  items: Conditions[];
}

interface conditions {
  conditions: Items;
}

interface TimeInput {
  ouIdentifier: string;
  wait_time: number;
}

interface LaterInput {
  ouIdentifier: string;
  dateOf: string;
  fromTime: string;
  toTime: string;
  message: string;
}

const Save: React.FC<IProps> = ({
  updateCondition,
  queue,
  department,
  input,
  information,
  setInformation,
  allOrOne,
  confirmationPage,
  setConfirmationPage,
  isScheduleSet,
  displayDepartment,
  displayScenario,
  setSaveError,
}): JSX.Element => {
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null
  );

  const popoverData = ["Log Out", "Go Back"];
  var count = 1;

  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal");
  document.body.append(modalRoot);

  const [displaySaveModal, setDisplaySaveModal] = useState<boolean>(false);

  const saveModalOnClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDisplaySaveModal(!displaySaveModal);
  };

  //for signing out
  function handleClose(
    event: React.MouseEvent<HTMLElement> | MouseEvent,
    index: number | null
  ) {
    setReferenceElement(null);
    if (index !== null) {
      if (index == 0) {
        signOut();
      } else {
        console.log("Back");
      }
    }
  }
  //sign out function
  async function signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }

  const ModalContainer = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
      `;
    }}
  `;
  const FlexRow = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        padding-top: 10px;
        display: flex;
        direction: row;
      `;
    }}
  `;

  const FlexCol = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        direction: column;
      `;
    }}
  `;

  const MessageBox = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        border: 2px solid ${theme?.colors.blue};
        background-color: #f4f6fa;
        padding: 15px;
      `;
    }}
  `;
  const Background = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        background-color: #f0f3f8;
        padding: 15px;
      `;
    }}
  `;

  const Circle = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        background: hsl(111, 40%, 84%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        line-height: 100px;
        padding: 10px;
        text-align: center;
      `;
    }}
  `;

  const a = "test";

  const themeContext = useContext(ThemeContext);

  return (
    <div style={{ float: "right" }}>
      <SAPopover
        background="rgba(0, 0, 0, 0.1)"
        data={popoverData}
        offset={[0, 15]}
        onClose={(e: MouseEvent, index: number | null) => handleClose(e, index)}
        placement="top"
        referenceElement={referenceElement}
        variant="popover-menu"
      />

      <SAButton
        fullWidth={false}
        label="Submit"
        onClick={() => {
          if (!(isScheduleSet && displayDepartment)) {
            setSaveError(true);
            window.scrollTo(0, 0);
          } else {
            setDisplaySaveModal(!displaySaveModal);
            setSaveError(false);
          }
        }}
        textTransform="uppercase"
        variant="primary-large"
        data-testid="submitButton"
      />
      {displaySaveModal && (
        <SAModal
          zIndex={9999}
          onClickHandler={(event: React.MouseEvent) => {}}
          data-testid="submitModal"
        >
          <ModalContainer>
            <SACard variant="minimal">
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <SAIcon
                  icon={SAIcons.x}
                  size={SAIconSize.medium}
                  colorVariant="dark"
                />
              </div>
              {confirmationPage && (
                <div style={{ display: "flex" }}>
                  <SAStack spacing="large">
                    <div
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        width: "750px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          background: "hsl(111, 40%, 84%)",
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          lineHeight: "100px",
                          padding: "10px",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <SAIcon
                          icon={SAIcons.success}
                          size={SAIconSize.medium}
                          colorVariant="secondary"
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <SAText
                        text="Success! IVR Queue Closure form submitted."
                        colorVariant="secondary"
                      />
                    </div>
                  </SAStack>
                </div>
              )}
              {!confirmationPage && (
                <SAStack spacing="large">
                  <SAStack spacing="medium">
                    <SAColumns columns={[4, 4, 4]} spacing="large">
                      <SAStack>
                        <SAText text="Department Name" weight="bold" />
                        <SAText text={department} />
                      </SAStack>

                      <SAStack>
                        <SAText text="Closed Queue(s)" weight="bold" />
                        <SAText text={queue} />
                      </SAStack>
                    </SAColumns>
                  </SAStack>

                  <Background>
                    <SAColumns columns={[4, 4, 4]} spacing="large">
                      <SAStack>
                        <SAText text="Date" weight="bold" />
                        <SAText text={information.dateOf} />
                      </SAStack>
                      <SAStack>
                        <SAText text="Start Time" weight="bold" />
                        <SAText text={information.fromTime} />
                      </SAStack>
                      <SAStack>
                        <SAText text="End Time" weight="bold" />
                        <SAText text={information.toTime} />
                      </SAStack>
                    </SAColumns>
                  </Background>

                  <div>
                    <SAText text="Selected Message" weight="bold" />
                    <MessageBox
                      style={{ marginTop: "20px", marginBottom: "30px" }}
                    >
                      <FlexRow>
                        <FlexCol style={{ marginRight: "10px" }}>
                          <SAIcon
                            icon={SAIcons.personHeadset}
                            size={SAIconSize.medium}
                            colorVariant={themeContext.colors.blue}
                          />
                        </FlexCol>
                        <FlexCol>{input.message}</FlexCol>
                      </FlexRow>
                    </MessageBox>
                  </div>
                </SAStack>
              )}
              {!confirmationPage && (
                <FlexRow style={{ justifyContent: "flex-end" }}>
                  <SAButton
                    fullWidth={false}
                    label="Edit Form"
                    textTransform="uppercase"
                    variant="link-large"
                    color="text"
                    onClick={() => {
                      setDisplaySaveModal(!displaySaveModal);
                    }}
                  />
                  <SAButton
                    fullWidth={false}
                    label="confirm"
                    textTransform="uppercase"
                    variant="primary-large"
                    onClick={() => {
                      setConfirmationPage(true);
                      updateCondition();
                    }}
                  />
                </FlexRow>
              )}

              {confirmationPage && (
                <FlexRow style={{ justifyContent: "flex-end" }}>
                  <SAButton
                    fullWidth={false}
                    label="Close"
                    textTransform="uppercase"
                    variant="primary"
                    onClick={() => {
                      window.location.reload();
                    }}
                  />
                </FlexRow>
              )}
            </SACard>
          </ModalContainer>
        </SAModal>
      )}
    </div>
  );
};

export default Save;
