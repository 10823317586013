import React, { useEffect, useState, useCallback, ReactNode } from "react";
import {
  applyNextFetchPolicy,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import Save from "./save";

interface Condition {
  department: string;
  wait_time: number;
  dateOf: string;
  fromTime: string;
  toTime: string;
  ouIdentifier: string;
  closed: boolean;
  play_message: boolean;
  message: string;
}

export interface ConditionInput {
  ouIdentifier: string;
  closed: boolean;
  play_message: boolean;
  message: string | ReactNode;
  dateOf: string;
  fromTime: string;
  toTime: string;
}

export interface InputMessage {
  messagePlain: String;
  message: string | ReactNode;
}

interface AllOrOneInterface {
  allOrOne: {
    input: string;
  };
}

export interface InformationInterface {
  information: ConditionInput;
}

type Message = React.ReactNode;

interface InputInterface {
  input: {
    message: Message;
    messagePlain: String;
  };
}

//input is the message passed to information that the caller hears
// information is all of the information required to make a graphql query
//allOrOne is a boolean value, are we closing all queues related to a department or just one?

interface SaveDataContainerProps {
  input: InputMessage;
  setInput: Function;
  information: InformationInterface["information"];
  setInformation: Function;
  allOrOne: boolean;
  confirmationPage: boolean;
  setConfirmationPage: Function;
  department: string;
  queue: string;
  isScheduleSet: boolean;
  displayDepartment: boolean;
  displayScenario: boolean;
  setSaveError: Function;
}

interface Conditions {
  closed: boolean;
  message: string;
  play_message: boolean;
  ouIdentifier: string;
  department: string;
  wait_time: number;
  dateOf: string;
  fromTime: string;
  toTime: string;
}

interface Items {
  items: Conditions[];
}

interface conditions {
  conditions: Items;
}

//mutation for updating conditions in table
//updates everything in conditionInput except ouIdentifier (key)

const updateConditionMutation = gql`
  mutation updateCondition($input: ConditionInput) {
    updateCondition(input: $input) {
      ouIdentifier
    }
  }
`;

const SaveDataContainer: React.FC<SaveDataContainerProps> = ({
  input,
  setInput,
  information,
  setInformation,
  allOrOne,
  confirmationPage,
  setConfirmationPage,
  department,
  queue,
  isScheduleSet,
  displayDepartment,
  displayScenario,
  setSaveError,
}) => {
  //build the mutation conditionInput
  const conditionInputObject: ConditionInput = {
    ouIdentifier: queue,
    closed: information.closed,
    play_message: information.play_message,
    message: input.message,
    dateOf: information.dateOf,
    fromTime: information.fromTime,
    toTime: information.toTime,
  };

  const [updateCondition, { error, data }] = useMutation(
    updateConditionMutation,
    {
      variables: {
        input: {
          ouIdentifier: queue,
          closed: information.closed,
          play_message: information.play_message,
          message: input.messagePlain,
          dateOf: information.dateOf,
          fromTime: information.fromTime,
          toTime: information.toTime,
          department: department,
        },
      },
    }
  );

  return (
    <Save
      updateCondition={updateCondition}
      confirmationPage={confirmationPage}
      setSaveError={setSaveError}
      isScheduleSet={isScheduleSet}
      displayDepartment={displayDepartment}
      displayScenario={displayScenario}
      queue={queue}
      department={department}
      information={information}
      input={input}
      setInput={setInput}
      setInformation={setInformation}
      allOrOne={allOrOne}
      setConfirmationPage={setConfirmationPage}
    />
  );
};

export default SaveDataContainer;
