import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  SACard,
  SAStack,
  SAText,
  SABox,
  SAAutocomplete,
} from "@saux/design-system-react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Auth } from "aws-amplify";

interface DepartmentProps {
  department: string;
  setDepartment: Function;
  queue: string;
  setQueue: Function;
  departmentQueues: string[];
  loading: boolean;
  displayDepartment: boolean;
  setDisplayDepartment: Function;
  isScheduleSet: boolean;
  setDepartmentQueues: Function;
}

interface Conditions {
  ouIdentifier: string; //This is the queue name
  closed: boolean;
  play_message: boolean;
  message: string;
  department: string;
}

interface Items {
  items: Conditions[];
}

interface conditions {
  dConditions: Items;
}

let departments = [
  "Claims",
  "Customer Service",
  "Platform",
  "Commercial Lines Underwriting",
  "Farm and Ranch Underwriting",
  "Personal Lines Underwriting",
  "Test Department",
];

const Flex = styled.div`
  ${(props: any) => {
    const { theme } = props;
    return css`
      display: flex;
      direction: row;
    `;
  }}
`;
const Col = styled.div`
  ${(props: any) => {
    const { theme } = props;
    return css`
      direction: column;
    `;
  }}
`;

const Department2: React.FC<DepartmentProps> = ({
  setDepartmentQueues,
  isScheduleSet,
  displayDepartment,
  setDisplayDepartment,
  department,
  setDepartment,
  queue,
  setQueue,
  departmentQueues,
  loading,
}): JSX.Element => {
  //for error flow purposes we need to define if a user is initially
  //filling out the department section or if they have
  //filled it out and erased something and triggered the red box around the inputs.
  const [firstPassThrough, setFirstPassThrough] = useState(true);

  const [isQueuePopulated, setIsQueuePopulated] = useState(false);

  const [cognitoUserArray, setCognitoUserArray] = useState<string[]>([]);
  let tmpArray: string[] = [];
  const userGroups = async () => {
    try{
      await Auth.currentSession().then((session) => {
        session.getIdToken().payload["cognito:groups"].map((group: string) => {
          tmpArray.push(group.replaceAll("_", " "));
        });
      });
      setCognitoUserArray(tmpArray);
    } catch {
      setCognitoUserArray(["User not assigned to department", "Contact ivralert@stateauto.com"]);
    }
    
    
    // console.log("Cognito user array: ", cognitoUserArray);
  };
  //adding an empty array as the second argument makes it only render once.
  useEffect(() => {
    userGroups();
  }, []);

  let allDepartments =
    cognitoUserArray.length > 0 && cognitoUserArray[0] !== "Admin"
      ? cognitoUserArray
      : departments;

  function isNullOrEmpty(value: any) {
    return value == null || value === "";
  }

  const onSelectQueue = (e: any) => {
    let queue = e.target.value;
    if (!isNullOrEmpty(queue)) {
      setQueue(queue);
      console.log("selected queue: ");
      console.log(queue);
    }
    setDisplayDepartment(true);
    setIsQueuePopulated(true);
    setFirstPassThrough(false);
  };

  useEffect(() => {
    console.log("COMPONENT", departmentQueues);
  }, [departmentQueues]);

  return (
    <div>
      {!isScheduleSet && (
        <SACard variant="minimal">
          <h3 style={{ color: "grey", fontSize: 22 }}>Department</h3>
        </SACard>
      )}
      {isScheduleSet && (
        <SACard variant="minimal">
          <div>
            <SABox pb="medium" sb="line" mb="large">
              <SAText type="heading-3" text="Department" />
            </SABox>

            <SAStack spacing="large">
              <SAText text="Select the department and queue to close" />

              <Flex>
                <Col style={{ marginRight: "10px" }}>
                  <SAAutocomplete
                    error={
                      (!displayDepartment || department.length < 1) &&
                      !firstPassThrough
                    }
                    fullWidth={true}
                    id="autocomplete1"
                    options={allDepartments}
                    label="Department"
                    hint={
                      (!displayDepartment || department.length < 1) &&
                      !firstPassThrough
                        ? "Select a department"
                        : ""
                    }
                    onChange={(e: any) => {
                      if (e.target.value) {
                        setDepartment(e.target.value);
                      } else {
                        setDisplayDepartment(false);
                      }
                    }}
                    data-testid="departmentAutoComplete"
                  />
                </Col>
                <Col>
                  <SAAutocomplete
                    error={!isQueuePopulated && !firstPassThrough}
                    hint={
                      !isQueuePopulated && !firstPassThrough
                        ? "Select a queue"
                        : ""
                    }
                    id="autocomplete2"
                    options={departmentQueues}
                    label="Queue"
                    onChange={(e: any) => {
                      if (e.target.value) {
                        onSelectQueue(e);
                      } else {
                        setDisplayDepartment(false);
                        setIsQueuePopulated(false);
                      }
                    }}
                    data-testid="queueAutoComplete"
                  />
                </Col>
              </Flex>
            </SAStack>
          </div>
        </SACard>
      )}
    </div>
  );
};

export default Department2;
