import React, { useEffect, useState, useCallback, ReactNode } from "react";
import {
  applyNextFetchPolicy,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import FNOL from "./fnol";

interface fnolDataContainerProps {
  fnolClosed: boolean;
  fnolWaitTime: number;
  setFnolClosed: Function;
  setFnolWaitTime: Function;
}

const updateFNOLMutation = gql`
  mutation updateFNOLQueue($input: FNOLInput) {
    updateFNOLQueue(input: $input) {
      ouIdentifier
    }
  }
`;

const FnolDataContainer: React.FC<fnolDataContainerProps> = ({
  fnolClosed,
  fnolWaitTime,
  setFnolClosed,
  setFnolWaitTime,
}) => {
  const [updateFNOL, { error, data }] = useMutation(updateFNOLMutation, {
    variables: {
      input: {
        ouIdentifier: "fnol-hold-queue",
        closed: fnolClosed,
      },
    },
  });
  return (
    <FNOL
      fnolClosed={fnolClosed}
      setFnolClosed={setFnolClosed}
      fnolWaitTime={fnolWaitTime}
      setFnolWaitTime={setFnolWaitTime}
      updateFNOL={updateFNOL}
    />
  );
};
export default FnolDataContainer;
