import React, { useEffect, useState, useCallback } from "react";
import {
  applyNextFetchPolicy,
  gql,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import Department2 from "./department2";
import { ConditionInput } from "../save/saveDataContainer";

interface DepartmentDataContainerProps {
  department: string;
  setDepartment: Function;
  queue: string;
  setQueue: Function;
  setInput: Function;
  information: ConditionInput;
  displayDepartment: boolean;
  setDisplayDepartment: Function;
  isScheduleSet: boolean;
  isTillEOD: boolean;
}

const Get_Queues = gql`
  query dConditions($department: String!) {
    dConditions(department: $department) {
      items {
        ouIdentifier
      }
    }
  }
`;

const DepartmentDataContainer: React.FC<DepartmentDataContainerProps> = ({
  isScheduleSet,
  displayDepartment,
  setDisplayDepartment,
  department,
  setDepartment,
  queue,
  setQueue,
  setInput,
  information,
  isTillEOD,
}) => {
  const updateInputMessage = () => {
    if (isTillEOD) {
      setInput({
        message: (
          <p>
            "Hello, and thank you for calling State Auto Insurance Companies.
            Our <strong>{department}</strong> department is currently
            unavailable. We apologize for the inconvenience. Please call back
            the next business day."
          </p>
        ),
        messagePlain:
          "<speak> Hello, and thank you for calling State Auto Insurance Companies. Our " +
          department +
          " department is currently unavailable. We apologize for the inconvenience. Please call back the next business day. </speak>",
      });
    } else if (information.toTime === "Manual") {
      let s: string =
        "<speak> Hello, and thank you for calling State Auto Insurance Companies. Our " +
        department +
        " department is currently unavailable.</speak>";
      setInput({
        message: (
          <p>
            "Hello, and thank you for calling State Auto Insurance Companies.
            Our <strong>{department}</strong> department is currently
            unavailable."
          </p>
        ),
        messagePlain: s,
      });
    } else {
      setInput({
        message: (
          <p>
            "Hello, and thank you for calling State Auto Insurance Companies.
            Our <strong>{department}</strong> department is currently in a
            department meeting. They will be available again at{" "}
            <strong>{information.toTime}</strong> Eastern Standard Time."
          </p>
        ),
        messagePlain:
          "<speak> Hello, and thank you for calling State Auto Insurance Companies. Our " +
          department +
          " department is currently in a department meeting. They will be available again at " +
          information.toTime +
          " Eastern Standard Time. </speak>",
      });
    }
  };

  const [departmentQueues, setDepartmentQueues] = useState<string[]>([
    "All Department Queues",
  ]);

  const [getDepartments, { loading, data }] = useLazyQuery(Get_Queues, {
    fetchPolicy: "no-cache",
  });

  const fetchDepartments = useCallback(() => {
    getDepartments({ variables: { department: department } });

    updateInputMessage();
  }, [department]);

  useEffect(() => {
    if (data && !loading && isScheduleSet) {
      setDepartmentQueues([
        "All Department Queues",
        ...data?.dConditions.items.map((items: any) => items.ouIdentifier)!,
      ]);
    }
  }, [data]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  return (
    <Department2
      isScheduleSet={isScheduleSet}
      displayDepartment={displayDepartment}
      setDisplayDepartment={setDisplayDepartment}
      department={department}
      setDepartment={setDepartment}
      queue={queue}
      setQueue={setQueue}
      setDepartmentQueues={setDepartmentQueues}
      departmentQueues={departmentQueues}
      loading={loading}
    />
  );
};
export default DepartmentDataContainer;
