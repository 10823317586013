import React, { useCallback, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  SAUX360Theme,
  SAFooter,
  SAHeader,
  GlobalStyle,
  SAStack,
  SAUXTheme,
  SAAlert,
  SAButton,
  SACard,
  SABox,
  SAText,
  SARadioGroup,
  SARadio,
  SATabBar,
} from "@saux/design-system-react";
import { Auth, Hub } from "aws-amplify";

import "./App.css";

import { Department2, Save, Scenario, Schedule, FNOL } from "./components";
import { ConditionInput } from "./components/save/saveDataContainer";

//sets up interface for variables that need to be passed through
// multiple files in Compnents

export type Message = React.ReactNode;

export interface IProps {
  input: {
    message: Message;
    messagePlain: String;
  };
}

export interface DepartmentInterface {
  department: {
    value: string;
  };
  queue: {
    value: string;
  };
}

export interface IProps3 {
  allOrOne: {
    input: boolean;
  };
}

export interface IProps4 {
  claimsSelected: {
    value: string;
  };
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  max-width: 1024px;
`;

function App() {
  /**
   * IVR Data UI - State:
   *
   * 1. SignedIn
   * 2. IsValidUser
   * 3. Time and day:
   *    3.a Now:
   *      Until time
   *      Until manually turn off
   *    3.b Later:
   *      Select date, from, to time
   * 4. Department
   * 5. Queue:
   *    5.a All department queues
   *    5.b Specific department queue
   * 6. Message
   *
   */

  //Is user signed in?
  const [signedIn, setSignedIn] = useState(false);
  //Is user a valid user?
  const [InvalidUser, setIsInvalidUser] = useState(false);

  useEffect(() => {
    //sets up cognito to sign in
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signOut":
          setSignedIn(false);
          break;
        case "signIn_failure":
          {
            const { message } = payload.data;
            if (message === "invalid_grant") {
              setIsInvalidUser(true);
            } else if (message === "invalid_request") {
              // doRedirectToRequestedPage();
              // setSignInErrorOccurred(true);
            }
          }
          break;
        default:
        // No action
      }
    });
  }, []);

  useEffect(() => {
    // Detect if the user is landing due to having just authenticated.
    if (signedIn) {
      // doRedirectToRequestedPage();
    } else {
      doSignIn();
    }
  }, [signedIn]);

  //if not logged in, redirect to login page
  function redirectToLoginPage(error: string) {
    console.log(error);
    if (error.includes("not authenticated")) {
      if (window.location.search.indexOf("?code=") === -1) {
        sessionStorage.setItem("redirectLocation", window.location.href);
        Auth.federatedSignIn().catch((_err) => {
          console.error("error signing in", _err);
        });
      }
    }
  }

  //redirect to signIn
  const doSignIn = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setSignedIn(true);
      })
      .catch((error) => {
        redirectToLoginPage(error);
      });
  }, []);

  //6. Messgae to be played to caller
  const [input, setInput] = useState<IProps["input"]>({
    message:
      "Hello, and thank you for calling State Auto Insurance Company. Our ______ department is currently unavailable. They will be available again at ______ Eastern Standard Time. ",
    messagePlain: "",
  });

  //These props are passed into department.
  //the queue will correspond to ouIdentifier
  const [department, setDepartment] = useState("");
  const [queue, setQueue] = useState("");

  //ouIdentifier - 5. Queue
  //message - 6. input
  //data fromtime totime - 3. Time and day
  const [information, setInformation] = useState<ConditionInput>({
    ouIdentifier: "western_queue",
    closed: false,
    play_message: false,
    message: input.messagePlain,
    dateOf: "7/27/2021",
    fromTime: "9:30 AM",
    toTime: "10:30 AM",
  });

  //5.a - all or one queue closed for department
  const [allOrOne, setAllOrOne] = useState<IProps3["allOrOne"]>({
    input: false,
  });

  const [confirmationPage, setConfirmationPage] = useState(false);

  const [displayScenario, setDisplayScenario] = useState(false);

  const [displayDepartment, setDisplayDepartment] = useState(false);

  const [isScheduleSet, setSchedule] = useState(false);

  const [saveError, setSaveError] = useState(false);

  const [fnolButton, setFnolButton] = useState(false);

  const [isFNOLUser, setIsFNOLUser] = useState(false);

  const [fnolClosed, setFnolClosed] = useState(false);

  const [fnolWaitTime, setFnolWaitTime] = useState(0);

  const [isTillEOD, setIsTillEOD] = useState(false);

  let tmpArray: string[] = [];

  const userGroups = async () => {
    try {
      await Auth.currentSession().then((session) => {
        session.getIdToken().payload["cognito:groups"].map((group: string) => {
          tmpArray.push(group.replaceAll("_", " "));
        });
      });
      if (tmpArray.includes("Admin")) {
        setIsFNOLUser(true);
      }
      console.log("tmp array from app.tsx", tmpArray);
    } catch {}
  };

  //adding an empty array as the second argument makes it only render once.
  useEffect(() => {
    userGroups();
  }, []);

  const latoTheme: SAUXTheme = {
    ...SAUX360Theme,
    font: {
      ...SAUX360Theme.font,
      primary: {
        ...SAUX360Theme.font.primary,
        family: "Lato, sans-serif",
        url: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap",
      },
    },
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const itemLabels = ["Queue Closure Form", "FNOL Hold Queue"];

  function testOnChange(newActiveIndex: number) {
    console.log(newActiveIndex);
    setActiveIndex(newActiveIndex);
  }

  if (signedIn === true) {
    return (
      <ThemeProvider theme={latoTheme}>
        <GlobalStyle />
        <SAHeader />
        <Container>
          <Wrapper>
            <SAStack spacing="large">
              <h2 style={{ marginTop: "50px", marginBottom: "50px" }}>
                IVR Queue Closure Form
              </h2>
              {isFNOLUser && (
                <SATabBar
                  activeIndex={activeIndex}
                  itemLabels={itemLabels}
                  onChange={testOnChange}
                />
              )}

              {activeIndex === 0 && (
                <SAStack spacing="large">
                  {saveError && (
                    <SAAlert severity="error">
                      Please complete the inputs before submitting the form
                    </SAAlert>
                  )}
                  <Schedule
                    isScheduleSet={isScheduleSet}
                    setSchedule={setSchedule}
                    department={department}
                    setInput={setInput}
                    information={information}
                    setInformation={setInformation}
                    setDepartment={setDepartment}
                    setIsTillEOD={setIsTillEOD}
                    isTillEOD={isTillEOD}
                  />
                  <Department2
                    isScheduleSet={isScheduleSet}
                    displayDepartment={displayDepartment}
                    setDisplayDepartment={setDisplayDepartment}
                    information={information}
                    setInput={setInput}
                    department={department}
                    setDepartment={setDepartment}
                    queue={queue}
                    setQueue={setQueue}
                    isTillEOD={isTillEOD}
                  />
                  <Scenario
                    displayScenario={displayScenario}
                    setDisplayScenario={setDisplayScenario}
                    displayDepartment={displayDepartment}
                    department={department}
                    information={information}
                    input={input}
                    setInput={setInput}
                    isScheduleSet={isScheduleSet}
                  />
                  <div style={{ paddingTop: "10px" }}>
                    <Save
                      confirmationPage={confirmationPage}
                      setSaveError={setSaveError}
                      isScheduleSet={isScheduleSet}
                      displayDepartment={displayDepartment}
                      displayScenario={displayScenario}
                      queue={queue}
                      department={department}
                      input={input}
                      setInput={setInput}
                      allOrOne={allOrOne.input}
                      information={information}
                      setInformation={setInformation}
                      setConfirmationPage={setConfirmationPage}
                    />
                  </div>
                </SAStack>
              )}
              {activeIndex === 1 && (
                <FNOL
                  fnolClosed={fnolClosed}
                  setFnolClosed={setFnolClosed}
                  fnolWaitTime={fnolWaitTime}
                  setFnolWaitTime={setFnolWaitTime}
                />
              )}
            </SAStack>
          </Wrapper>
        </Container>
        <SAFooter />
      </ThemeProvider>
    );
  } else {
    return <div>Signing In</div>;
  }
}

export default App;
