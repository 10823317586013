import React, { useContext, useEffect, useState } from "react";
import styled, { css, ThemeContext, ThemeProvider } from "styled-components";
import {
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SARadio,
  SARadioGroup,
  SASelect,
  SAStack,
  SAText,
} from "@saux/design-system-react";

interface fnolProps {
  fnolClosed: boolean;
  fnolWaitTime: number;
  setFnolClosed: Function;
  setFnolWaitTime: Function;
  updateFNOL: Function;
}

const FNOL: React.FC<fnolProps> = ({
  fnolClosed,
  fnolWaitTime,
  setFnolClosed,
  setFnolWaitTime,
  updateFNOL,
}): JSX.Element => {
  const items = [
    {
      label: "1 minute",
      value: "1",
    },
    {
      label: "2 minutes",
      value: "2",
    },
    {
      label: "3 minutes",
      value: "3",
    },
    {
      label: "4 minutes",
      value: "4",
    },
    {
      label: "5 minutes",
      value: "5",
    },
    {
      label: "6 minutes",
      value: "6",
    },
    {
      label: "7 minutes",
      value: "7",
    },
    {
      label: "8 minutes",
      value: "8",
    },
    {
      label: "9 minutes",
      value: "9",
    },
    {
      label: "10 minutes",
      value: "10",
    },
    {
      label: "11 minutes",
      value: "11",
    },
    {
      label: "12 minutes",
      value: "12",
    },
  ];

  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal");
  document.body.append(modalRoot);

  const [displayModal, setDisplayModal] = useState(false);
  const [confirmationPage, setConfirmationPage] = useState(false);

  const ModalContainer = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        width: "600px";
      `;
    }}
  `;
  const FlexRow = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        padding-top: 10px;
        display: flex;
        direction: row;
      `;
    }}
  `;
  return (
    <div>
      <SACard variant="minimal">
        <p>
          Turning on the FNOL Hold Queue sets a mandatory 3-minute hold for the
          caller before they enter the claims queue.
        </p>
        <br />

        <SARadioGroup
          id="question01"
          value="OFF"
          label="FNOL Hold Queue"
          onChange={(e) => {
            console.log("Toggling FNOL closed to: ", e.target.value);
            if (e.target.value === "ON") {
              setFnolClosed(true);
            } else if (e.target.value === "OFF") {
              setFnolClosed(false);
            }
          }}
        >
          <SARadio value="ON" label="ON" />
          <SARadio value="OFF" label="OFF" />
        </SARadioGroup>
        {/* <br />
            <SASelect 
            label="FNOL Hold Time" 
            id="select01" 
            options={items} 
            onChange={(e:any) => {
                setFnolWaitTime(e.target.value);
                console.log("Setting FNOL wait time to: ", e.target.value);
            }}
            /> */}
      </SACard>
      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SAButton
          fullWidth={false}
          label="Submit Hold Queue Change"
          onClick={() => {
            updateFNOL();
            setDisplayModal(!displayModal);
          }}
          textTransform="uppercase"
          variant="primary"
        />
      </div>
      {displayModal && (
        <SAModal zIndex={9999} onClickHandler={(event: React.MouseEvent) => {}}>
          <ModalContainer>
            <SACard variant="minimal">
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log("clicked");
                  window.location.reload();
                  setDisplayModal(!displayModal);
                }}
              >
                <SAIcon
                  icon={SAIcons.x}
                  size={SAIconSize.medium}
                  colorVariant="dark"
                />
              </div>
              <SAStack spacing="medium">
                <div style={{ display: "flex" }}>
                  <SAStack spacing="large">
                    <div
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        width: "600px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          background: "hsl(111, 40%, 84%)",
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          lineHeight: "100px",
                          padding: "10px",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <SAIcon
                          icon={SAIcons.success}
                          size={SAIconSize.medium}
                          colorVariant="secondary"
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {fnolClosed && (
                        <div>
                          <SAText
                            text="Success! FNOL Hold Queue was "
                            colorVariant="secondary"
                          />
                          <SAText
                            colorVariant="secondary"
                            type="standard"
                            text="added"
                            weight="bold"
                          />
                        </div>
                      )}
                      {!fnolClosed && (
                        <div>
                          <SAText
                            text="Success! FNOL Hold Queue was "
                            colorVariant="secondary"
                          />
                          <SAText
                            type="standard"
                            text="removed"
                            weight="bold"
                            colorVariant="secondary"
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "15px",
                        }}
                      >
                        <SAButton
                          fullWidth={false}
                          label="Close"
                          onClick={() => {
                            window.location.reload();
                          }}
                          textTransform="uppercase"
                          variant="primary"
                        />
                      </div>
                    </div>
                  </SAStack>
                </div>
              </SAStack>
            </SACard>
          </ModalContainer>
        </SAModal>
      )}
    </div>
  );
};

export default FNOL;
