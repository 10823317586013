import React, { ReactNode, useContext, useEffect, useState } from "react";
import styled, { css, ThemeContext, ThemeProvider } from "styled-components";
import {
  SAUX360Theme,
  SARadio,
  SARadioGroup,
  SAButton,
  SAText,
  SACard,
  SAColumns,
  SAStack,
  SAInput,
  GlobalStyle,
  SAIcon,
  SAIcons,
  SAIconSize,
  SABox,
} from "@saux/design-system-react";
import { render } from "react-dom";
import { manual, time } from "../schedule/schedule";
import { ConditionInput } from "../save/saveDataContainer";

export var reason: string = "Department Meeting";
export var reasonClked: string = "Department Meeting";

interface MessageInterface {
  message: ReactNode;
}

interface ScenarioProps {
  input: MessageInterface;
  setInput: Function;
  information: ConditionInput;
  department: string;
  displayScenario: boolean;
  setDisplayScenario: Function;
  displayDepartment: boolean;
  isScheduleSet: boolean;
}

const Scenario: React.FC<ScenarioProps> = ({
  displayScenario,
  setDisplayScenario,
  displayDepartment,
  department,
  information,
  input,
  setInput,
  isScheduleSet,
}): JSX.Element => {
  //reason for queue closure

  // const [reasonClicked, setReasonClicked] = useState("");

  //if manual only have department
  //else department and time open

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const MessageBox = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        border: 2px solid ${theme?.colors.blue};
        background-color: #f4f6fa;
        padding: 15px;
      `;
    }}
  `;

  const themeContext = useContext(ThemeContext);

  const Flex = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        display: flex;
        direction: row;
      `;
    }}
  `;

  const Col = styled.div`
    ${(props: any) => {
      const { theme } = props;
      return css`
        direction: column;
      `;
    }}
  `;

  return (
    <div>
      {(!displayDepartment || !isScheduleSet) && (
        <SACard variant="minimal">
          <h3 style={{ color: "grey", fontSize: 22 }}>Selected Message</h3>
        </SACard>
      )}
      {displayDepartment && isScheduleSet && (
        <SACard variant="minimal">
          <div>
            <SABox pb="medium" sb="line" mb="large">
              <SAText type="heading-3" text="Selected Message" />
            </SABox>
            <SAStack spacing="large">
              {/* <SARadioGroup id="question06" label="Select the reason for requesting queue closure" variant="standard" direction="vertical" onChange={(e) => reasonClicked(e) } >
                
                <SARadio value="Emergency Closure" label="Emergency Closure" variant="standard"  />
                <SARadio value="Department Meeting" label="Department Meeting" variant="standard" disabled = {manual}/>
            </SARadioGroup> */}

              <SAText type="heading-4" text=" " />
              <SAText type="heading-4" text=" " />

              <MessageBox data-testid="messageBox">
                <Flex>
                  <Col style={{ marginRight: "10px" }}>
                    <SAIcon
                      icon={SAIcons.personHeadset}
                      size={SAIconSize.medium}
                      colorVariant={themeContext.colors.blue}
                      data-testid="icon"
                    />
                  </Col>
                  <Col data-testid="inputMessage">{input.message}</Col>
                </Flex>
              </MessageBox>
            </SAStack>
          </div>
        </SACard>
      )}
    </div>
  );
};

export default Scenario;
